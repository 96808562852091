@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  font-family: 'Raleway', sans-serif;
  background-color: #F5F5F5;
}

/* handle offline carousel styles */
.flickity-prev-next-button:nth-of-type(1) {
  left: 10px;
  border-radius: 50px;
}

.flickity-prev-next-button:nth-of-type(2) {
  right: 10px;
  border-radius: 50px;
}

.intro {
  text-align: center;
  color: #FFFFFF;
  background: #252934;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro>div>h2 {
  font-weight: 200;
  font-size: 30px;
  font-family: 'Raleway Light', sans-serif;
}

.intro span {
  color: #E0226E;
  font-weight: 600;
  font-family: 'Raleway Medium', sans-serif;
}

.intro h2:first-of-type {
  margin-top: 0;
}

.introbutton {
  outline: 1px solid #FFFFFF;
  background: none;
  color: #FFFFFF;
  padding: 15px 20px;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  margin: 40px auto 0 auto;
  cursor: pointer;
  transition: all .5s;
}

.introbutton>div {
  width: 20%;
  height: 3.3vh;
  background-image: url(images/right.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.introbutton.true>div,
.introbutton:hover>div {
  background-image: url(images/down.png);
}

.introbutton.true,
.introbutton:hover,
input[type="submit"]:hover {
  background: #21C2C8;
  outline: none;
  border: none;
}

.nav {
  width: 100%;
  height: 70px;
  display: flex;
  background-color: #1B242E;
  color: #FFFFFF;
  border-bottom: 5px solid #21C2C8;
  z-index: 1;
  position: sticky;
  top: 0;
}

.nav>* {
  font-size: 20px;
  margin: auto 5%;
  cursor: pointer;
}

.nav>div:hover,
.navitem.true {
  color: #E0226E;
}

.about,
.portfolio,
.contact {
  padding-top: 100px;
}

.about,
.portfolio {
  width: 100%;
  color: #444649;
  background: #F5F5F5;
}

.title {
  text-align: center;
  margin-bottom: 1%;
  letter-spacing: 1px;
}

.titleunderline {
  background-color: #444649;
  width: 45px;
  height: 3px;
  margin: auto;
}

.about {
  display: flex;
  flex-direction: column;
}

.about>p {
  margin: 5% 10%;
  text-align: center;
  font-size: 18px;
  line-height: 25px;
}

.about>p>span {
  color: #26A1DA;
  margin: 6px;
  cursor: pointer;
}

.skills {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
}

.skill-section {
  display: flex;
  flex-direction: column;
}

.skill-section>h2 {
  font-size: 23px;
}

.skill-listing {
  padding: 5% 0;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
}

.skill-listing>img {
  width: 50px;
  height: 50px;
  margin-right: 10%;
}

.skill-listing>div {
  font-size: 20px;
}

.mobile-skill {
  display: none;
}

.projects {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 5%;
  margin: 5% auto 0 auto;
}

.project {
  width: 40%;
  height: 50vh;
  background: center center/cover;
  text-align: center;
}

.project>button {
  font-family: 'Raleway', sans-serif;
  visibility: hidden;
  position: relative;
  border: 1px solid #E0226E;
  background: #F5F5F5;
  padding: 10px 30px;
  font-size: 20px;
  bottom: -30vh;
  transition: bottom .5s;
  letter-spacing: .25px;
  font-weight: 250;
}

.project>h1 {
  visibility: hidden;
  position: relative;
  top: 0;
  transition: top .5s;
}

.project:hover {
  background-image: none !important;
}

.project:hover>button {
  outline: 0;
  visibility: visible;
  bottom: -15vh;
}

.project:hover>button:hover {
  background: #E0226E;
  color: #FFF;
  cursor: pointer;
}

.project:hover>h1 {
  visibility: visible;
  top: 10%;
}

#log {
  background-image: url(images/log.png);
}

#flowers {
  background-image: url(images/18flogo.png);
}

#paws {
  background-image: url(images/paws.png);
}

#moms {
  background-image: url(images/moms.png);
}

.project.dark:hover>* {
  visibility: hidden;
}

#log.project.dark:hover {
  background-image: url(images/log.png) !important;
}

#flowers.project.dark:hover {
  background-image: url(images/18flogo.png) !important;
}

#moms.project.dark:hover {
  background-image: url(images/moms.png) !important;
}

#paws.project.dark:hover {
  background-image: url(images/paws.png) !important;
}

.dark {
  filter: brightness(0.5);
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  height: 100vh;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
}

.close-popup {
  display: none;
}

.project-details {
  width: 100%;
  height: 100%;
  text-align: center;
}

.project-link {
  cursor: pointer;
  color: #E0226E;
  margin: 5% auto;
}

.project-link:hover {
  filter: invert(0.25);
}

.project-desc {
  font-size: 20px;
  margin: auto 10%;
  line-height: 26px;
}

.divider {
  display: flex;
  overflow: hidden;
}

.first-half {
  border-left: calc(50vw) solid #252933;
  border-top: 12vh solid #F5F5F5;
}

.second-half {
  border-right: calc(50vw) solid #252933;
  border-top: 12vh solid #F5F5F5;
}

.contact {
  color: #FFFFFF;
  background: #252934;
}

.contact>.titleunderline {
  background-color: #FFFFFF;
}

.contact>form {
  width: 60%;
  margin: 5% auto 0 auto;
  padding-bottom: 15%;
  display: flex;
  flex-direction: column;
}

input {
  margin: .5% 0;
  padding: 10px;
  font-size: 18px;
  font-family: 'Raleway';
}

textarea {
  margin: .5% 0;
  height: 20vh;
  padding: 10px;
  font-size: 18px;
  font-family: 'Raleway';
}

input[type="submit"] {
  width: 25%;
  height: 50px;
  margin-top: 3%;
  font-weight: 600;
  letter-spacing: .75px;
  align-self: flex-end;
  background-color: transparent;
  color: #FFFFFF;
  background-color: #262F37;
  cursor: pointer;
  border: 2px solid #FFFFFF;
}

.success {
  background-color: #04c986;
  color: #157051;
  text-align: center;
  padding: 5px;
  position: relative;
}

.success>span {
  cursor: pointer;
  margin-right: 0;
  position: absolute;
  right: 0;
  padding-right: 10px;
}

.footer {
  background: #1B242E;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.backtotop {
  width: 100%;
  text-align: center;
  outline: 0;
}

.backtotop>img {
  position: relative;
  top: -40px;
  width: 80px;
  height: 80px;
  cursor: pointer;
}

.backtotop>img:hover {
  filter: invert(0.1);
}

.medias {
  width: 100%;
  margin: 5% auto;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.media {
  width: 80px;
  height: 80px;
  margin: auto 3%;
  background-position: center;
  background-size: cover;
}

.media:hover {
  filter: invert(0.1);
}

#linkedin {
  background-image: url(images/linkedin.png);
}

#email {
  background-image: url(images/email.png);
}

#git {
  background-image: url(images/git.png);
}

#resume {
  background-image: url(images/resume.png);
}

.copyright {
  color: #7A7A7A;
  margin-bottom: 5%;
}

.copyright>span {
  color: #E0226E;
  letter-spacing: .5px;
}

/* DESKTOP */

@media only screen and (min-width: 1300px) {

  .about>p {
    font-size: 24px;
    line-height: 30px;
    margin: 5% 15%;
  }

  .nav>* {
    font-size: 20px;
  }

  .carousel {
    height: 50% !important;
  }

  .project {
    width: 35%;
    height: 40vh;
  }

  .popup {
    width: 900px;
  }

  .project-link {
    margin: 7% auto;
  }

  .contact>form {
    width: 45%;
    padding-bottom: 10%;
  }
}

/* LAPTOP */

@media only screen and (min-width: 1024px) {}

/* IPAD PRO */

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {

  .intro>div {
    width: 75%;
  }

  .nav>* {
    font-weight: 600;
  }

  .skill-section {
    width: 40%;
  }

  .skill-section>h2 {
    font-size: 30px;
  }

  .project {
    height: 35vh;
    width: 50%;
  }

  .project>button {
    bottom: -17.5vh;
  }

  .project:hover>button {
    bottom: -10vh;
  }

  .popup {
    width: 100%;
    height: 65vh;
    top: 17vh;
  }

  .carousel {
    height: 65% !important;
  }

  .project-link {
    font-size: 32px;
    margin: 4% auto;
  }

  .project-desc {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 1px;
    margin: auto 5%;
  }

  .contact>form {
    width: 75%;
  }

  input[type="submit"] {
    width: 33%;
  }
}

@media only screen and (max-width: 900px) {

  .skills {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
  }

  .skill-section {
    width: 100%;
    text-align: center;
    display: block;
  }

  .skill-listing {
    margin: auto 4%;
    width: auto;
    display: inline-block;
  }

  .skill-listing>div {
    margin-top: 5%;
    font-size: 16px;
  }

  .mobile-skill {
    display: block;
    transition: all 1s ease-out;
  }

  .project {
    width: 50%;
    ;
  }

  .project-link {
    margin: 3% auto;
  }

  .popup {
    width: 80%;
  }

  .close-popup {
    color: gray;
    display: block;
    font-size: 30px;
    position: relative;
    left: calc(100% - 33px);
    top: 5px;
    cursor: pointer;
  }

  .carousel {
    height: 50%;
  }

  .nav>* {
    font-size: 16px;
  }

}

/* IPAD */

@media only screen and (min-width: 768px) and (max-height: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {

  .popup {
    width: 100%;
    height: 80vh;
    top: 10vh;
  }

  .close-popup {
    width: 100px;
    height: 50px;
    font-size: 40px;
  }

  .project-details {
    display: flex;
    flex-direction: column;
  }

  .carousel {
    order: 3;
  }

  .project-link {
    order: 2;
    font-size: 30px;
    margin-bottom: 7%;
  }

  .project-desc {
    font-size: 24px;
    margin: 5%;
    order: 1;
  }

}

@media only screen and (max-width: 700px) {

  .popup {
    width: 100%;
  }

}

/* PHONE */

@media only screen and (max-width: 600px) {

  .nav>* {
    font-size: 13px;
  }

  .intro>div {
    width: 80%;
  }

  .intro>div>h2 {
    font-size: 22px;
  }

  .introbutton {
    padding: 4%;
  }

  .project {
    width: 80%;
    cursor: pointer;
  }

  #log:hover {
    background-image: url(images/log.png) !important;
  }

  #flowers:hover {
    background-image: url(images/18flogo.png) !important;
  }

  #moms:hover {
    background-image: url(images/moms.png) !important;
  }

  #paws:hover {
    background-image: url(images/paws.png) !important;
  }

  .carousel-cell>p {
    font-size: 14px;
  }

  .carousel-cell>p {
    margin: 5px auto;
  }

  .project-link {
    margin: 0;
  }

  .project-desc {
    font-size: 16px;
    margin: 5%;
  }

  .title {
    margin-top: 0;
  }

  .backtotop>img {
    height: 50px;
    width: 50px;
    top: -25px;
  }

  .contact>form {
    width: 85%;
  }

  .contact>form>* {
    margin: 1% 0;
  }

  .media {
    height: 50px;
    width: 50px;
  }

  input[type="submit"] {
    margin-top: 2%;
    width: 33%;
  }

  @media only screen and (max-width: 400px) {

    .project-desc {
      margin: 2%;
      font-size: 14px;
    }

  }

}